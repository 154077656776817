import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const DworekSkrudaHalinowPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Dworek Skruda w Halinowie" />
      <ReturnToPrevPage to={'/gminy/halinow'}>
        Materiały dotyczące gminy Halinów
      </ReturnToPrevPage>
      <Header1>Dworek Skruda w Halinowie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Majątek Skruda powstał w 1630 roku. W jego skład wchodziły dobra
          ziemskie w Długiej Kościelnej, stawy rybne oraz dwory. Majątkami
          zarządzali administratorzy. Zapewne pod koniec XIX lub na począdku XX
          wieku właściciele majątku Skruda zbudowali dworek.
        </p>
        <p>
          Dwór był budowlą skromną i niedużą, ale jego architektura i otoczenie
          było interesujące. Posiadłość wraz z budynkami gospodarczymi otoczona
          była pięknym parkiem, ze wspaniałym drzewostanem, w którym znajdował
          się staw i altana. W 1933 r. właścicielem folwarku Skruda był ksiądz
          prałat Zygmunt Karczyński, który tuż przed wojną resztę majątku
          sprzedał, a sam wyemigrował do Anglii. Ostatnim właścicielem majątku
          Skruda był dr medycyny pan Łytkowski, na stałe mieszkający w
          Warszawie. Jego dobrami zarządzał p. Kuźma. Był to wspaniały
          administrator, który majątek doprowadził do rozwkitu. Wprowadził
          innowacje rolnicze, a okoliczni chłopi uczyli się od niego gospodarki
          rolnej.
        </p>
        <p>
          Dwór i majątek pozostał w rękach państwa Łytkowskich do końca II wojny
          światowej. Po wojnie w 1944 r. dwór, podobnie jak wiele budowli tego
          typu, przeznaczono na cele oświatowe organizując w niej szkołę
          podstawową, a majątek ziemski rozparcelowano między okolicznych
          chłopów. Pod koniec lat 80-tych podjęto decyzję o budowie nowej
          szkoły. Jej budowa została zakończona w 1995 roku, a budynek został
          zaadaptowany dla potrzeb Domu Kultury.
        </p>
        <p>Fot. GCK</p>
      </MonumentContentWrapper>
      {/* <StyledGallery images={images} /> */}
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/halinow/dworek-skruda/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/halinow/dworek-skruda/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DworekSkrudaHalinowPage
